import React, { useContext, useEffect, useState } from "react";
import { pointGrid } from "@turf/turf";
import { useHotkeys } from "react-hotkeys-hook";

import { MapContext } from "../Map";

function calculateDistance(lat1, lon1, lat2, lon2) {
  const R = 6371; // Earth's radius in kilometers
  const dLat = deg2rad(lat2 - lat1);
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers

  return distance * 1000;
}

function deg2rad(deg) {
  return deg * (Math.PI / 180);
}

export default function TillerLayer({
  id,
  bounds,
  minZoom,
  maxZoom,
  tilesUrl,
  footprintUrl,
  seamlinesUrl,
  gcps,
  frames,
}) {
  const map = useContext(MapContext);

  const [showRoads, setShowRoads] = useState(false);
  const [showSeamlines, setShowSeamlines] = useState(false);
  const [showFootprint, setShowFootprint] = useState(false);
  const [showFrames, setShowFrames] = useState(false);
  const [showCounties, setShowCounties] = useState(false);
  const [coverageOpacity, setCoverageOpacity] = useState(1);
  const [clicks, setClicks] = useState([]);
  const [lastClick, setLastClick] = useState(null);
  const [selectedGridPointIndex, setSelectedGridPointIndex] = useState(null);
  const [zoomed, setZoomed] = useState(false);
  const [gridPoints, setGridPoints] = useState([]);

  useEffect(() => {
    if (!map) return;
    if (zoomed) return;

    map.on("zoom", (e) => {
      setZoomed(true);
    });
  }, [map, zoomed]);

  useEffect(() => {
    if (!map) return;
    if (selectedGridPointIndex == null) return;

    setShowRoads(true);
    let feature = gridPoints.features[selectedGridPointIndex];
    let coords = feature.geometry.coordinates;

    let options = {
      center: coords,
    };
    if (!zoomed) {
      options.zoom = 14;
    }

    map.jumpTo(options);

    // update progress bar
    const targetElement = document.getElementById("progress");
    targetElement.style.width =
      (selectedGridPointIndex / gridPoints.features.length) * 100 + "%";
  }, [gridPoints, selectedGridPointIndex, zoomed]);

  useEffect(() => {
    if (!map) return;

    if (clicks.length % 2 === 0) {
      // get last two coordinates and calc distance using `calculateDistance`:
      let dist = calculateDistance(
        clicks[clicks.length - 2].lat,
        clicks[clicks.length - 2].lng,
        clicks[clicks.length - 1].lat,
        clicks[clicks.length - 1].lng,
      );
      console.log(dist);
    }
  }, [clicks]);

  useEffect(() => {
    if (lastClick) {
      setClicks([...clicks, lastClick]);
    }
  }, [lastClick]);

  useEffect(() => {
    if (!map) return;

    map.on("click", (e) => {
      let f = map.unproject(e.point);
      let lat = f.lat.toFixed(6);
      let lng = f.lng.toFixed(6);
      let coords = `${lat},${lng}`;

      setLastClick(f);

      console.log(coords);

      navigator.clipboard.writeText(coords).then(() => {});
    });

    map.on("click", (e) => {
      let features = map.queryRenderedFeatures(e.point, {
        layers: ["seamlines"],
      });

      if (features.length) {
        let clickedFeature = features[0];
        if (clickedFeature.properties.NAME) {
          window.open(`/usgs/gcps/${clickedFeature.properties.NAME}`);
        }
      }
    });

    map.on("boxzoomend", (e) => {
      let f = map.getBounds();
      let bounds = `${f.getWest()},${f.getSouth()},${f.getEast()},${f.getNorth()}`;

      navigator.clipboard.writeText(bounds).then(
        function () {
          alert("Copied BBOX to clipboard: " + bounds);
        },
        function (err) {
          alert("Error copyuing BBOX to clipboard: " + err);
        },
      );

      console.log({ f, bounds });
    });

    map.addSource(`tiller-${id}`, {
      type: "raster",
      scheme: "tms",
      tiles: [tilesUrl],
      minzoom: minZoom,
      maxzoom: maxZoom,
      tileSize: 128, // 256 if not retina
      bounds,
    });

    map.addLayer(
      {
        id: `raster-${id}`,
        type: "raster",
        source: `tiller-${id}`,
        paint: {
          "raster-fade-duration": 150,
        },
      },
      "satellite-Tunnel",
    );

    map.setPaintProperty(
      "satellite-Road",
      "line-color",
      "rgba(255, 0, 0, 0.5)",
    );
  }, [map]);

  // add a layer with a square grid of the bounds:
  useEffect(() => {
    if (!map) return;

    let points = pointGrid(bounds, 10, { units: "kilometers" });
    console.log(points);
    console.log("SETTING GRID POINTS");
    setGridPoints(points);

    // map.addSource("grid", {
    //   type: "geojson",
    //   data: points,
    // });

    // // add a point layer
    // map.addLayer({
    //   id: "grid",
    //   type: "circle",
    //   source: "grid",
    //   paint: {
    //     "circle-color": "#f00",
    //     "circle-radius": 4,
    //     "circle-stroke-width": 1,
    //     "circle-stroke-color": "#fff",
    //   },
    // });
  }, [map]);

  useEffect(() => {
    if (!map) return;
    if (!gcps) return;

    map.addSource("gcps", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: gcps.map((gcp) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [gcp.lng, gcp.lat],
          },
          properties: {
            id: gcp.id,
          },
        })),
      },
    });

    map.addLayer({
      id: "gcps-marker",
      type: "circle",
      source: "gcps",
      paint: {
        "circle-color": "#f00",
        "circle-radius": 4,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    });

    map.addLayer({
      id: "gcps-label",
      type: "symbol",
      source: "gcps",
      paint: {
        "text-color": "#f00",
        "text-halo-color": "#fff",
        "text-halo-width": 1,
      },
      layout: {
        "text-field": "{id}",
        "text-font": ["Helvetica Bold"],
        "text-size": 12,
        "text-offset": [0, 1],
      },
    });
  }, [map, gcps]);

  useEffect(() => {
    console.log("frames", frames);
    if (!map) return;
    if (!frames) return;

    map.addSource("frames", {
      type: "geojson",
      data: {
        type: "FeatureCollection",
        features: frames.map((frame) => ({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [frame.lng, frame.lat],
          },
          properties: frame,
        })),
      },
    });

    map.addLayer({
      id: "frames-marker",
      type: "circle",
      source: "frames",
      paint: {
        "circle-color": "#9b59b6",
        "circle-radius": 4,
        "circle-stroke-width": 1,
        "circle-stroke-color": "#fff",
      },
    });

    map.addLayer({
      id: "frames-label",
      type: "symbol",
      source: "frames",
      minzoom: 12,
      paint: {
        "text-color": "#9b59b6",
        "text-halo-color": "#fff",
        "text-halo-width": 1,
      },
      layout: {
        "text-field": "{photo_id}",
        "text-font": ["Helvetica Bold"],
        "text-size": 12,
        "text-offset": [0, 1],
      },
    });
  }, [map, frames]);

  useEffect(() => {
    if (!map) return;
    if (!footprintUrl) return;

    map.addSource("footprint", {
      type: "geojson",
      data: footprintUrl,
    });

    map.addLayer({
      id: "footprint",
      type: "line",
      source: "footprint",
      paint: {
        "line-color": "#f1c40f",
        "line-width": 2,
      },
    });
  }, [map, footprintUrl]);

  useEffect(() => {
    if (!map) return;
    if (!seamlinesUrl) return;

    map.addSource("seamlines", {
      type: "geojson",
      data: seamlinesUrl,
    });

    map.addLayer({
      id: "seamlines-labels",
      type: "symbol",
      minzoom: 10,
      source: "seamlines",
      layout: {
        "text-font": ["Open Sans Regular"],
        "text-field": "{NAME}",
        "text-size": 14,
      },
      paint: {
        "text-color": "#00ff00",
        "text-halo-color": "#000000",
        "text-halo-width": 1,
      },
    });

    map.addLayer({
      id: "seamlines",
      type: "fill",
      source: "seamlines",
      paint: {
        "fill-color": "#00ff00",
        "fill-opacity": 0,
      },
    });

    map.addLayer({
      id: "seamlines-line",
      type: "line",
      source: "seamlines",
      paint: {
        "line-color": "#00ff00",
      },
    });
  }, [map, seamlinesUrl]);

  useEffect(() => {
    if (!map) return;

    map.addSource("counties", {
      type: "vector",
      tiles: ["https://s3.amazonaws.com/va-tiles/coverage/{z}/{x}/{y}.pbf"],
      minzoom: 0,
      maxzoom: 14
    });

    map.addLayer({
      id: "county-boundaries",
      type: "line",
      source: "counties",
      "source-layer": "counties",
      paint: {
        "line-color": "#3498db",
        "line-width": 1
      },
      layout: {
        visibility: "none"
      }
    });

    map.addLayer({
      id: "county-labels",
      type: "symbol",
      source: "counties",
      "source-layer": "counties",
      paint: {
        "text-color": "#3498db",
        "text-halo-color": "#ffffff",
        "text-halo-width": 1
      },
      layout: {
        "text-field": ["concat", ["get", "NAME"], "\n", ["get", "GEOID"]],
        "text-font": ["Open Sans Regular"],
        "text-size": 12,
        "text-anchor": "center",
        visibility: "none"
      }
    });
  }, [map]);

  useEffect(() => {
    if (!map) return;

    map.setPaintProperty(`raster-${id}`, "raster-opacity", coverageOpacity);
  }, [map, coverageOpacity]);

  useEffect(() => {
    if (!map) return;
    if (!map.getLayer("frames-label")) return;

    map.setLayoutProperty(
      "frames-label",
      "visibility",
      showFrames ? "visible" : "none",
    );

    map.setLayoutProperty(
      "frames-marker",
      "visibility",
      showFrames ? "visible" : "none",
    );
  }, [map, showFrames]);

  useEffect(() => {
    if (!map) return;
    if (!map.getLayer("footprint")) return;

    map.setLayoutProperty(
      "footprint",
      "visibility",
      showFootprint ? "visible" : "none",
    );
  }, [map, showFootprint]);

  useEffect(() => {
    if (!map) return;

    let layers = ["satellite-Road"];
    if (gcps) layers.push("gcps-marker", "gcps-label");

    for (const layer of layers) {
      map.setLayoutProperty(
        layer,
        "visibility",
        showRoads ? "visible" : "none",
      );
    }
  }, [map, showRoads]);

  useEffect(() => {
    if (!map) return;

    let layers = [];
    if (seamlinesUrl)
      layers.push("seamlines", "seamlines-line", "seamlines-labels");

    for (const layer of layers) {
      map.setLayoutProperty(
        layer,
        "visibility",
        showSeamlines ? "visible" : "none",
      );
    }
  }, [map, showSeamlines]);

  useEffect(() => {
    if (!map) return;
    if (!map.getLayer("county-boundaries")) return;

    map.setLayoutProperty(
      "county-boundaries",
      "visibility",
      showCounties ? "visible" : "none"
    );
    map.setLayoutProperty(
      "county-labels",
      "visibility",
      showCounties ? "visible" : "none"
    );
  }, [map, showCounties]);

  useHotkeys("0", () => {
    setCoverageOpacity((previousCoverageOpacity) =>
      previousCoverageOpacity == 0 ? 1 : 0,
    );
  });

  useHotkeys("f", () => {
    setShowFootprint((previousShowFootprint) => !previousShowFootprint);
  });

  useHotkeys("p", () => {
    setShowFrames((previousShowFrames) => !previousShowFrames);
  });

  useHotkeys("l", () => {
    setShowRoads((previousShowRoads) => !previousShowRoads);
  });
  useHotkeys("r", () => {
    setShowRoads((previousShowRoads) => !previousShowRoads);
  });

  useHotkeys("s", () => {
    setShowSeamlines((previousShowSeamlines) => !previousShowSeamlines);
  });

  useHotkeys("c", () => {
    setShowCounties((previousShowCounties) => !previousShowCounties);
  });

  useHotkeys(
    "]",
    () => {
      if (!gridPoints) return;
      if (gridPoints.length == 0) return;

      if (selectedGridPointIndex == null) {
        setSelectedGridPointIndex(0);
      } else {
        setSelectedGridPointIndex((previousSelectedGridPointIndex) => {
          if (previousSelectedGridPointIndex == gridPoints.length - 1) {
            return 0;
          } else {
            return previousSelectedGridPointIndex + 1;
          }
        });
      }
    },
    [selectedGridPointIndex, gridPoints],
  );

  useHotkeys(
    "[",
    () => {
      if (!gridPoints) return;
      if (gridPoints.length == 0) return;

      if (selectedGridPointIndex == null) {
        setSelectedGridPointIndex(gridPoints.length - 1);
      } else {
        setSelectedGridPointIndex((previousSelectedGridPointIndex) => {
          if (previousSelectedGridPointIndex == 0) {
            return gridPoints.length - 1;
          } else {
            return previousSelectedGridPointIndex - 1;
          }
        });
      }
    },
    [selectedGridPointIndex, gridPoints],
  );

  return (
    <div className="absolute z-10 m-2 top-0 right-0">
      <div className="flex flex-col space-y-2">
        <label className="flex items-center">
          <input
            className="p-4 mr-1"
            type="checkbox"
            checked={showRoads}
            onChange={() =>
              setShowRoads((previousShowRoads) => !previousShowRoads)
            }
          />
          <div className="select-none">
            Road <span className="underline">L</span>ines
          </div>
        </label>
        {seamlinesUrl && (
          <label className="flex items-center">
            <input
              className="p-4 mr-1"
              type="checkbox"
              checked={showSeamlines}
              onChange={() =>
                setShowSeamlines(
                  (previousShowSeamlines) => !previousShowSeamlines,
                )
              }
            />
            <div>
              <span className="underline">S</span>eamlines
            </div>
          </label>
        )}
        {footprintUrl && (
          <label className="flex items-center">
            <input
              className="p-4 mr-1"
              type="checkbox"
              checked={showFootprint}
              onChange={() =>
                setShowFootprint(
                  (previousShowFootprint) => !previousShowFootprint,
                )
              }
            />
            <div>
              <span className="underline">F</span>ootprint
            </div>
          </label>
        )}
        {frames && (
          <label className="flex items-center">
            <input
              className="p-4 mr-1"
              type="checkbox"
              checked={showFrames}
              onChange={() =>
                setShowFrames((previousShowFrames) => !previousShowFrames)
              }
            />
            <div>
              <span className="underline">P</span>hoto Frames
            </div>
          </label>
        )}
        <label className="flex items-center">
          <input
            className="p-4 mr-1"
            type="checkbox"
            checked={showCounties}
            onChange={() =>
              setShowCounties((previousShowCounties) => !previousShowCounties)
            }
          />
          <div>
            <span className="underline">C</span>ounty Boundaries
          </div>
        </label>
      </div>
    </div>
  );
}
